





























import { ActionFeedbackResult } from '@/models/interfaces'
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'

export default Vue.extend({
  name: 'ActionFeedbackDisplay',
  props: {
    actionFeedbackType: String,
  },
  data() {
    return {
      showAlert: true,
    }
  },
  computed: {
    ...mapGetters(['getActionFeedback']),
    actionFeedbackResult(): ActionFeedbackResult | null {
      if (
        this.getActionFeedback &&
        this.getActionFeedback.type === this.actionFeedbackType
      ) {
        // console.log('the action feedback result is: ', this.getActionFeedback.result)
        return this.getActionFeedback.result
      }
      return null
    },
  },
  watch: {
    actionFeedbackResult: function () {
      setTimeout(() => this.closeClick(), 5000)
    },
  },
  methods: {
    ...mapActions(['resetActionFeedback']),
    closeClick() {
      this.showAlert = false
      setTimeout(() => {
        this.resetActionFeedback(this.actionFeedbackType)
        this.showAlert = true // reset alert state, alert will not show because actionFeedback as been nulled
      }, 1000)
    },
  },
})
