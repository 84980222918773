<template>
  <v-row align="stretch" align-content="start" justify="center" no-gutters>
    <v-col>
      <v-card class="ma-4">
        <v-card-text>
          <v-tabs>
            <v-tab href="#userlist"> User List </v-tab>
            <v-tab-item value="userlist">
              <UserList />
            </v-tab-item>
            <v-tab href="#emailListsAdmin"> Manage Email Lists </v-tab>
            <v-tab-item value="emailListsAdmin">
              <EmailListsAdmin />
            </v-tab-item>
            <v-tab href="#buyerLists"> View Buyer Compares </v-tab>
            <v-tab-item value="buyerLists">
              <BuyerLists :display="'inner'" viewSet="admin" />
            </v-tab-item>
          </v-tabs>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src
import UserList from '@/components/UserList.vue'
import EmailListsAdmin from '@/components/EmailListsAdmin.vue'
import BuyerLists from '@/components/BuyerLists.vue'

export default {
  name: 'SystemAdmin',
  components: {
    UserList,
    EmailListsAdmin,
    BuyerLists,
  },
}
</script>
