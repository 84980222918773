








































































































































































































import Vue from 'vue'
import ActionFeedbackDisplay from '@/components/ActionFeedbackDisplay.vue'
import EmailListEdit from '@/components/EmailListEdit.vue'
import { mapGetters, mapActions } from 'vuex'
import { EmailListData, EmailListToEdit } from '@/models/interfaces'

import { statesDropdown } from '@/libs/StatesDropdown'

import { getFunctions, httpsCallable } from '@firebase/functions'
import { Timestamp } from '@firebase/firestore'
import { getDownloadURL, getStorage, ref } from '@firebase/storage'

export default Vue.extend({
  name: 'EmailLists',
  components: {
    ActionFeedbackDisplay,
    EmailListEdit,
  },
  data: (): {
    search: string
    itemsPerPage: number
    footerProps: {
      itemsPerPageOptions: number[]
    }
    dialogEdit: string[]
    tagSearch: string[]
    states: { text: string; value: string }[] // Vuetify dropdown type
    selectedState: string
    selectedStateCompare: boolean
    activeTag: string
    showUnpublished: boolean
    emailListToEdit: EmailListToEdit | null
    downloadUrl: string
    downloadSnackbar: boolean
  } => {
    return {
      search: '',
      itemsPerPage: 20,
      footerProps: {
        itemsPerPageOptions: [20, 50, 100, -1],
      },
      dialogEdit: [],
      tagSearch: [],
      states: statesDropdown,
      selectedState: '',
      selectedStateCompare: true,
      activeTag: '',
      showUnpublished: false,
      emailListToEdit: null,
      downloadUrl: '',
      downloadSnackbar: false,
    }
  },
  computed: {
    ...mapGetters([
      'getEmailListsAdmin',
      'getUser',
      'getUserPermissions',
      'getUserById',
    ]),
    emailLists(): EmailListData[] {
      return this.getEmailListsAdmin.filter((list: EmailListData) => {
        return list.published === !this.showUnpublished
      })
    },
    emailListsHeader(): { text: string; value: string }[] {
      const headers = [
        { text: 'List Name', value: 'name' },
        { text: 'Price per name', value: 'pricePerName' },
        { text: 'Count', value: 'count' },
        { text: 'Description', value: 'description' },
        { text: 'Tags', value: 'tags' },
        { text: 'Owner', value: 'userId' },
        { text: 'Last Updated', value: 'timestampUpdated' },
        { text: 'Published', value: 'published' },
        { text: 'Approved', value: 'approved' },
        { text: '', value: 'actions' },
      ]
      return headers
    },
    selectedStateText(): string {
      const stateObj = this.states.find((state) => {
        return state.value === this.selectedState
      })
      return stateObj ? stateObj.text : 'Unknown state'
    },
    existingTags() {
      const tags: string[] = []
      this.getEmailListsAdmin.forEach((emailList: EmailListData) => {
        if (Array.isArray(emailList.tags)) {
          tags.push(...emailList.tags)
        }
      })
      return tags
    },
  },
  watch: {
    getUserPermissions() {
      // see if we need to load (or 'unload') email lists
      this.loadList()
    },
  },
  methods: {
    ...mapActions([
      'bindEmailListsToDb',
      'updateActionFeedback',
      'updateEmailListApproved',
      'deleteEmailList',
    ]),
    loadList() {
      if (
        this.getUserPermissions &&
        this.getUserPermissions.role === 'admin' &&
        !this.getEmailListsAdmin.length
      ) {
        this.bindEmailListsToDb({ viewSet: 'admin' })
      }
    },
    tagClickSearch() {
      this.search = this.activeTag
    },
    approveEmailListToggle(emailList: EmailListData) {
      if (
        !this.getUserPermissions ||
        this.getUserPermissions.role !== 'admin'
      ) {
        return
      }
      // emailList.approved works because function is sent current front-end state (which was just toggled)
      const payload = { id: emailList.id, approved: emailList.approved }
      this.updateEmailListApproved(payload)
    },
    closeEdit(itemId: string) {
      this.dialogEdit = this.dialogEdit.filter((e) => e !== itemId)
    },
    generateDownloadUrl(emailList: EmailListData) {
      // only admins can download files
      if (this.getUserPermissions && this.getUserPermissions.role !== 'admin') {
        console.log('Permission denied')
        return
      }
      const functions = getFunctions()
      const generateDownloadFile = httpsCallable(
        functions,
        'generateListDownloadFile'
      )
      generateDownloadFile({ emailListId: emailList.id })
        .then((result) => {
          console.log('result', result.data)
          let downloadFilePath = ''
          if (typeof result.data === 'string') {
            downloadFilePath = result.data
          }
          const storageBucket = 'listbetter-downloads'
          const storage = getStorage(undefined, storageBucket)
          getDownloadURL(ref(storage, downloadFilePath))
            .then((url) => {
              this.downloadUrl = url
              this.downloadSnackbar = true
            })
            .catch((error) => {
              console.log(error)
            })
        })
        .catch((error) => {
          console.log(error)
        })
    },
    reprocessEmailList(emailList: EmailListData) {
      if (this.getUserPermissions && this.getUserPermissions.role !== 'admin') {
        console.log('Permission denied')
        return
      }
      const lastProcessedString =
        emailList.timestampProcessed instanceof Timestamp
          ? emailList.timestampProcessed.toDate()
          : 'unknown'
      const confirmText =
        emailList.name +
        ' was last processed on ' +
        lastProcessedString +
        '\nAre you sure you want to reprocess this email list?'
      const confirmed = confirm(confirmText)
      if (confirmed) {
        const functions = getFunctions()
        const reprocessEmailListCall = httpsCallable(
          functions,
          'reprocessEmailList'
        )
        reprocessEmailListCall({ emailListId: emailList.id })
      }
    },
    removeEmailList(emailList: EmailListData) {
      if (this.getUserPermissions && this.getUserPermissions.role !== 'admin') {
        console.log('Permission denied')
        return
      }
      const confirmText =
        'Are you sure you want to delete this email list "' +
        emailList.name +
        '"?'
      const confirmed = confirm(confirmText)
      if (confirmed) {
        this.deleteEmailList(emailList.id)
      }
    },
  },
  created() {
    this.loadList()
  },
})
