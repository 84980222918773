var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-4",attrs:{"flat":_vm.display === 'inner'}},[_c('v-card-title',[(_vm.viewSet === 'own')?_c('span',[_vm._v("Your Compared Lists")]):_c('span',[_vm._v("Buyer Compared Lists")]),_c('v-spacer'),_c('v-text-field',{staticClass:"search-input",attrs:{"prepend-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-text-field',{staticClass:"limit-random-order-input",attrs:{"label":"Download names limit (randomized)","prepend-icon":"mdi-download","hide-details":""},model:{value:(_vm.limitRandomOrder),callback:function ($$v) {_vm.limitRandomOrder=$$v},expression:"limitRandomOrder"}})],1),_c('v-card-text',[_c('v-data-table',{staticClass:"email-lists",attrs:{"headers":_vm.buyerListsHeader,"items":_vm.buyerLists,"item-key":"index","search":_vm.search,"items-per-page":_vm.itemsPerPage,"footer-props":_vm.footerProps,"no-data-text":_vm.noDataText,"sort-by":"timestampUpdated","sort-desc":true,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([(_vm.viewSet === 'admin')?{key:"item.buyerUserId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getUserById(item.buyerUserId).email)+" ")]}}:null,{key:"item.newNames",fn:function(ref){
var item = ref.item;
return [(item.newNames)?_c('span',[_vm._v(" "+_vm._s(item.newNames.toLocaleString())+" ")]):_c('span',[_vm._v("Unknown")])]}},{key:"item.timestampUpdated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.timestampUpdated ? item.timestampUpdated.toDate().toDateString() : 'Unknown')+" ")]}},(_vm.viewSet === 'admin')?{key:"item.sellerListId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getEmailListByIdAdmin(item.sellerListId).name)+" ")]}}:null,{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.viewSet === 'admin')?_c('v-btn',{staticClass:"ma-2 ml-0",attrs:{"color":"primary","small":"","dark":""},on:{"click":function($event){return _vm.generateDownloadUrl(item.sellerListId, item.id)}}},[_vm._v(" Download "+_vm._s(_vm.limitRandomOrder && item.newNames > _vm.limitRandomOrder ? Number(_vm.limitRandomOrder).toLocaleString() + ' random' : 'all')+" names ")]):_vm._e(),(_vm.viewSet === 'admin')?_c('v-btn',{staticClass:"ma-2 ml-0",attrs:{"color":"danger","small":"","dark":""},on:{"click":function($event){return _vm.deleteListCompare(item.sellerListId, item.id)}}},[_vm._v(" Delete ")]):_vm._e()]}}],null,true)})],1),_c('v-card-actions'),_c('v-snackbar',{attrs:{"multi-line":true,"vertical":true,"timeout":"-1","text":"","color":"primary"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"red","text":""},on:{"click":function($event){_vm.downloadSnackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.downloadSnackbar),callback:function ($$v) {_vm.downloadSnackbar=$$v},expression:"downloadSnackbar"}},[_c('a',{attrs:{"href":_vm.downloadUrl}},[_vm._v("DOWNLOAD LINK")]),_c('br'),_vm._v(" File is available for five minutes. ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }