

































































































































import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { BuyerListData } from '@/models/interfaces'

import { getFunctions, httpsCallable } from '@firebase/functions'
import { getDownloadURL, getStorage, ref } from '@firebase/storage'

export default Vue.extend({
  name: 'BuyerLists',
  props: {
    display: String,
    viewSet: {
      type: String, // own, admin
      default: 'own',
    },
  },
  data: (): {
    search: string
    itemsPerPage: number
    footerProps: {
      itemsPerPageOptions: number[]
    }
    limitRandomOrder: number | null
    downloadUrl: string
    downloadSnackbar: boolean
  } => {
    return {
      search: '',
      itemsPerPage: 20,
      footerProps: {
        itemsPerPageOptions: [20, 50, 100, -1],
      },
      limitRandomOrder: null,
      downloadUrl: '',
      downloadSnackbar: false,
    }
  },
  computed: {
    ...mapGetters([
      'getBuyerListsOwn',
      'getBuyerListsAdmin',
      'getUser',
      'getUserPermissions',
      'getUserById',
      'getEmailListByIdAdmin',
    ]),
    buyerLists(): BuyerListData[] {
      // create manual index because id is buyerUserId
      // accross multiple seller lists, will have dups
      if (this.viewSet === 'own') {
        return this.getBuyerListsOwn
      } else if (this.viewSet === 'admin') {
        return this.getBuyerListsAdmin
      } else {
        return []
      }
    },
    buyerListsHeader(): { text: string; value: string }[] {
      const headers = [
        { text: 'Buyer', value: 'buyerUserId' },
        { text: 'New Names', value: 'newNames' },
        { text: 'Date', value: 'timestampUpdated' },
        { text: 'File Name', value: 'buyerListFileName' },
        { text: 'File Length', value: 'buyerListLength' },
      ]
      if (this.viewSet === 'admin') {
        headers.push({ text: 'Seller List', value: 'sellerListId' })
      }
      headers.push({ text: 'State Selection', value: 'stateSelection' })
      headers.push({ text: '', value: 'actions' })
      return headers
    },
    noDataText() {
      let text = ''
      if (!this.getUser) {
        text = 'Checking for permissions...'
      } else {
        text = 'No lists to display yet'
      }
      return text
    },
  },
  watch: {
    getUserPermissions() {
      // see if we need to load (or 'unload') email lists
      this.loadList()
    },
  },
  methods: {
    ...mapActions(['bindEmailListsToDb', 'bindBuyerListsToDb']),
    loadList() {
      if (
        this.getUserPermissions &&
        (this.getUserPermissions.role === 'member' ||
          this.getUserPermissions.role === 'admin')
      ) {
        if (this.viewSet === 'own' && !this.getBuyerListsOwn.length) {
          this.bindBuyerListsToDb({ viewSet: this.viewSet })
        }
        if (
          this.viewSet === 'admin' &&
          this.getUserPermissions.role === 'admin' &&
          !this.getBuyerListsAdmin.length
        ) {
          this.bindEmailListsToDb({ viewSet: this.viewSet })
          this.bindBuyerListsToDb({ viewSet: this.viewSet })
        }
      }
    },
    generateDownloadUrl(sellerListId: string, buyerListId: string) {
      // only admins can download files
      if (this.getUserPermissions && this.getUserPermissions.role !== 'admin') {
        console.log('Permission denied')
        return
      }
      if (!sellerListId || !buyerListId) {
        console.log('Did not receive list id or buyer list id')
        return
      }
      const functions = getFunctions()
      const generateDownloadFile = httpsCallable(
        functions,
        'generateListDownloadFile'
      )
      generateDownloadFile({
        emailListId: sellerListId,
        buyerListId: buyerListId,
        limitRandomOrder: this.limitRandomOrder,
      })
        .then((result) => {
          console.log('result', result.data)
          let downloadFilePath = ''
          if (typeof result.data === 'string') {
            downloadFilePath = result.data
          }
          const storageBucket = 'listbetter-downloads'
          const storage = getStorage(undefined, storageBucket)
          getDownloadURL(ref(storage, downloadFilePath))
            .then((url) => {
              this.downloadUrl = url
              this.downloadSnackbar = true
            })
            .catch((error) => {
              console.log('Something went wrong: ', error)
            })
        })
        .catch((error) => {
          console.log(error)
        })
    },
    deleteListCompare(sellerListId: string, buyerListId: string) {
      // only admins can download files
      if (this.getUserPermissions && this.getUserPermissions.role !== 'admin') {
        console.log('Permission denied')
        return
      }
      if (!sellerListId || !buyerListId) {
        console.log('Did not receive list id or buyer id')
        return
      }
      const confirmed = confirm('Are you sure?')
      if (!confirmed) {
        return
      }
      console.log('going to delete list compare...')
      const functions = getFunctions()
      const deleteListCompareCall = httpsCallable(
        functions,
        'deleteBuyerListCompare'
      )
      deleteListCompareCall({
        emailListId: sellerListId,
        buyerListId: buyerListId,
      })
        .then((result) => {
          console.log('result', result.data)
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
  created() {
    this.loadList()
  },
})
