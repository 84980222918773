





















































































import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { EmailListToEdit } from '@/models/interfaces'

export default Vue.extend({
  name: 'EmailLists',
  components: {},
  props: {
    emailListToEdit: {
      type: Object as () => EmailListToEdit | null,
    },
    existingTags: Array, // string array
    userType: String, // 'member', 'admin'
  },
  data: (): {
    rules: {
      text: { (v: string): boolean | string }[]
      number: { (v: string): boolean | string }[]
    }
    tagSearch: string
    inputsAreValid: boolean
    emailListNewProps: EmailListToEdit | null
  } => {
    return {
      rules: {
        text: [(v: string) => !!v || 'Field is required'],
        number: [
          (v: string) =>
            !v || /^[0-9]\d*(\.\d+)?$/.test(v) || 'Valid amount is required',
        ],
      },
      tagSearch: '',
      inputsAreValid: true,
      emailListNewProps: null,
    }
  },
  computed: {
    ...mapGetters(['getUser', 'getUserPermissions', 'getUserById']),
    form(): Vue & { validate: () => boolean } {
      return this.$refs.EmailListEditForm as Vue & { validate: () => boolean }
    },
    isUsersList() {
      if (
        this.emailListNewProps &&
        this.emailListNewProps.userId === this.getUser.id
      ) {
        return true
      }
      return false
    },
    isAdmin() {
      if (this.getUserPermissions && this.getUserPermissions.role === 'admin') {
        return true
      }
      return false
    },
  },
  methods: {
    ...mapActions(['updateEmailList']),
    closeEdit() {
      this.$emit('close-modal', true)
    },
    saveEmailList() {
      // validate and check access permissions
      this.form.validate()

      if (!this.inputsAreValid) {
        console.log('Form validation error')
        return
      }
      if (this.emailListNewProps) {
        if (!this.isUsersList && !this.isAdmin) {
          console.log('Permission denied')
          return
        }
        if (this.emailListNewProps.ownerTrashed) {
          console.log('Not allowed to edit lists in trash')
          return
        }
      } else {
        console.log('No list selected to edit')
        return
      }
      // make sure we have an emailListNewProps object
      console.log(this.emailListNewProps.name)
      if (this.emailListNewProps) {
        this.updateEmailList({
          emailListNewProps: this.emailListNewProps,
        })
        this.closeEdit()
      }
    },
  },
  created() {
    if (this.emailListToEdit) {
      // need to make a deep copy since we're being passed the actual object on the list
      this.emailListNewProps = JSON.parse(JSON.stringify(this.emailListToEdit))
      if (this.emailListNewProps && this.isAdmin && !this.isUsersList) {
        // admins can only edit tags
        this.emailListNewProps = {
          id: this.emailListNewProps.id,
          userId: this.emailListNewProps.userId,
          ownerTrashed: this.emailListNewProps.ownerTrashed,
          tags: this.emailListNewProps.tags,
        }
      }
    }
  },
})
